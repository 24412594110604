import { render, staticRenderFns } from "./CustomerMaintainence.vue?vue&type=template&id=c10724de"
import script from "./CustomerMaintainence.vue?vue&type=script&lang=js"
export * from "./CustomerMaintainence.vue?vue&type=script&lang=js"
import style0 from "./CustomerMaintainence.vue?vue&type=style&index=0&id=c10724de&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports