<template>
    <v-dialog max-width="700" persistent v-model="dialog">
        <v-card>
            <v-card-title class="headline">{{$parent.selectedApplication.scoringTriggeredByOfficer ? 'Customer Information' : 'Generate Score'}}</v-card-title>
            <v-card-text>
                <v-card>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field :label="$t('lbl.application_number')" readonly
                                        v-model="$parent.selectedApplication.applicationNumber">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field :label="$t('lbl.id_number')" readonly
                                        v-model="$parent.selectedApplication.idNumber">
                          </v-text-field>
                        </v-col>
                      </v-row>

                        <v-row>
                          <v-col cols="12" md="12">
                            <v-text-field :label="$t('lbl.full_name')" readonly
                                          v-model="$parent.selectedApplication.fullname"></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field :label="$t('lbl.gender')" readonly
                                          v-model="$parent.selectedApplication.gender">
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field :label="$t('lbl.position')" readonly
                                          v-model="$parent.selectedApplication.designation">
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field :label="$t('lbl.business_reg_num')" readonly
                                          v-model="$parent.selectedApplication.businessRegistrationNumber">
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field :label="$t('lbl.company_name')" readonly
                                          v-model="$parent.selectedApplication.companyName">
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field :label="$t('lbl.branch')" readonly
                                          v-model="$parent.selectedApplication.branch"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field :label="$t('lbl.product_applied')" readonly
                                          v-model="$parent.selectedApplication.productAppliedLabel"></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field :label="$t('lbl.business_category')" readonly
                                              v-model="$parent.selectedApplication.businessCategoryLabel"></v-text-field>
                            </v-col>
<!--                          <v-col cols="12" md="6">
                            <v-text-field :label="$t('lbl.business_size')" readonly
                                          v-model="$parent.selectedApplication.employeesCount"></v-text-field>
                          </v-col>-->
                        </v-row>
<!--                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field :label="$t('lbl.annual_sales')" prefix="$" readonly
                                        v-model="$parent.selectedApplication.annualSales"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field :label="$t('lbl.annual_profit')" readonly prefix="$"
                                        v-model="$parent.selectedApplication.annualProfit"></v-text-field>
                        </v-col>
                      </v-row>-->
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field :label="$t('lbl.financing_amount_applied')" prefix="$" readonly
                                        v-model="$parent.selectedApplication.loanAmount"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field :label="$t('lbl.financing_tenure_applied')" readonly suffix="Months"
                                        v-model="$parent.selectedApplication.tenureApplied"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                </v-card>
                <div>
                    <div v-show="!$parent.selectedApplication.scoringTriggeredByOfficer" class="mt-2">
                        <p>{{ $t('msg.score_generation_text_1') }}<br/>{{ $t('msg.score_generation_text_2') }}</p>
                    </div>
                    <div v-show="scoreGenerationError">
                        <p class="red--text">{{ $t('error.score_generate_error_text_1') }}<br/>{{ $t('error.score_generate_error_text_2') }}</p>
                    </div>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-skeleton-loader class="mr-3" :loading="loading" transition="scale-transition" type="button">
                    <v-btn @click="dialog=false" color="primary" right>{{$parent.selectedApplication.scoringTriggeredByOfficer ? 'Close' : 'Cancel'}}</v-btn>
                </v-skeleton-loader>
                <v-skeleton-loader v-if="!$parent.selectedApplication.scoringTriggeredByOfficer" :loading="loading" transition="scale-transition" type="button">
                    <v-btn @click="sendGenerateScoreRequest" class="mr-4" color="primary" dark right>{{ $t('yes') }}</v-btn>
                </v-skeleton-loader>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import {dataServices} from "@/services";

export default {
        data() {
            return {
                dialog: false,
                dropdown: {},
                scoreGenerationError: false,
                loading: false
            }
        }, methods: {
            init() {
                this.dialog = true
                this.initDropdown()
                this.scoreGenerationError= false
                // console.log(this.$parent.selectedApplication)
            },
            sendGenerateScoreRequest() {
                this.scoreGenerationError= false
                this.loading= true
                dataServices.scoreCustomer(this.$parent.selectedApplication).then(() => {
                    this.loading= false
                    this.$parent.renderMessage('GFI Credit Sore for Customer : '+ this.$parent.selectedApplication.username +' was generated successfully')
                    this.dialog = false
                }).catch(error => {
                    this.loading= false
                    this.scoreGenerationError=true
                    console.log('Error' + error)
                })
            },
            initDropdown: function () {
                dataServices.getUserDropDowns(this.$parent.userType.toUpperCase()).then(response => {
                    this.dropdown = response.dataResponse
                    this.loading = false
                })
            }
        },

      computed: {
        monthsCount: function () {
          return this.$parent.selectedApplication.tenureApplied % 12;
        },
        yearsCount: function () {
          return Math.floor(this.$parent.selectedApplication.tenureApplied / 12);
        },
      },
    }
</script>

<style>
.title-tenure {
  margin-bottom: -30px !important;
  margin-top: 10px !important;
  font-size: 12px;
}
</style>
